@import url("https://fonts.googleapis.com/css?family=Space+Mono");
.number__wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  height: 200px;
  position: absolute;
}
.number {
  font-family: "Space Mono";
  font-size: 1em;
  transform: translateY(70px);
  animation: rising-numbers 0.7s linear infinite;
}
.number::before {
  content: "1";
}
.number:nth-child(even)::before {
  content: "0";
}
.number:nth-child(2) {
  animation-delay: 0.3s;
}
.number:nth-child(3) {
  animation-delay: 0.1s;
}
.number:nth-child(4) {
  animation-delay: 0.6s;
}
.number:nth-child(5) {
  animation-delay: 0.4s;
}
.number:nth-child(6) {
  animation-delay: 0.5s;
}
.number:nth-child(7) {
  animation-delay: 0.2s;
}
.number:nth-child(8) {
  animation-delay: 0.7s;
}
.number:nth-child(9) {
  animation-delay: 0.3s;
}
.number:nth-child(10) {
  animation-delay: 0.6s;
}
@-moz-keyframes rising-numbers {
  from {
    transform: translateY(70px);
    opacity: 0;
  }
  to {
    transform: translateY(-70px);
    opacity: 1;
  }
}
@-webkit-keyframes rising-numbers {
  from {
    transform: translateY(70px);
    opacity: 0;
  }
  to {
    transform: translateY(-70px);
    opacity: 1;
  }
}
@-o-keyframes rising-numbers {
  from {
    transform: translateY(70px);
    opacity: 0;
  }
  to {
    transform: translateY(-70px);
    opacity: 1;
  }
}
@keyframes rising-numbers {
  from {
    transform: translateY(70px);
    opacity: 0;
  }
  to {
    transform: translateY(-70px);
    opacity: 1;
  }
}
/*# sourceMappingURL=src/pages/public/LoginPageLoader.css.map */