.metadataVForm {
  overflow-y: scroll;
}
@media (min-width: 360px) {
  .metadataVForm {
    max-height: 38vh;
  }
}
@media (min-width: 600px) {
  .metadataVForm {
    max-height: 38vh;
  }
}
@media (min-width: 960px) {
  .metadataVForm {
    max-height: 43vh;
  }
}
@media (min-width: 1264px) {
  .metadataVForm {
    max-height: 48vh;
  }
}
@media (min-width: 1904px) {
  .metadataVForm {
    max-height: 53vh;
  }
}
.item-center-horizontal {
  display: grid;
  grid-auto-flow: column;
  gap: 4px;
  align-items: center;
  justify-items: center;
}
.item-center-vertical {
  padding: 20vh 0;
  text-align: center;
}
/*# sourceMappingURL=src/components/MetadataComponent.css.map */