


























































.table-hover-cursor
  tbody
    tr:hover
      cursor: pointer;
