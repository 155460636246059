.loader {
  width: 30px;
  height: 30px;
  position: relative;
  margin: auto;
}
.loader::before,
.loader::after {
  content: '';
  position: absolute;
}
.loader-item {
  display: flex;
}
.loader-butterfly {
  perspective: 500px;
  transform-style: preserve-3d;
  transform: rotate3d(2, 1, -1, 90deg);
}
.loader-butterfly::before,
.loader-butterfly::after {
  border-radius: 50%;
  width: 16px;
  height: 16px;
  top: calc(50% - 8px);
  background: #f00;
  -webkit-animation-duration: 3s;
  animation-duration: 3s;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
}
.loader-butterfly::before {
  left: 0;
  opacity: 0.7;
  border-top-right-radius: 0;
  transform-origin: right top;
  -webkit-animation-name: flapL;
  animation-name: flapL;
}
.loader-butterfly::after {
  left: 50%;
  opacity: 0.5;
  border-top-left-radius: 0;
  transform-origin: left top;
  -webkit-animation-name: flapR;
  animation-name: flapR;
}
@-moz-keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@-webkit-keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@-o-keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@-moz-keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@-webkit-keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@-o-keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@keyframes flapL {
  25%, 50% {
    transform: rotateY(-45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(45deg);
  }
  0%, 100% {
    transform: rotateY(-30deg);
  }
}
@-moz-keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
@-webkit-keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
@-o-keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
@keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
@-moz-keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
@-webkit-keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
@-o-keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
@keyframes flapR {
  25%, 50% {
    transform: rotateY(45deg);
  }
  12.5%, 37.5%, 65% {
    transform: rotateY(-45deg);
  }
  0%, 100% {
    transform: rotateY(30deg);
  }
}
/*# sourceMappingURL=src/components/common/Loading.css.map */