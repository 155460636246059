






.checkbox-style {
  border: 1px solid gray;
  height: 40px;
  margin-bottom: 20px;
  padding-left: 6px;
  background-color: var(--v-textFieldPrimary-base);
}
