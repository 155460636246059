.text {
  font-size: 12px;
  border-radius: 10px;
}
.alternate-background {
  background: #eee;
}
.normal-background {
  background: #e0e0e0;
}
.progressBorder {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 1rem;
}
/*# sourceMappingURL=src/components/UploadFile.css.map */