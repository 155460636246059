






















.copyText
  width: 100%
  font-size: 11px
  font-family: Monaco, Menlo, "Ubuntu Mono", Consolas, source-code-pro, monospace
  color: white
