



































































.no-uppercase
  text-transform: unset !important;

.no-hover
  tbody
    tr:hover
      background-color: transparent !important;
