




















































.v-input--switch {
  display: inline-block;
}
