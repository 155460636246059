















@import url("https://fonts.googleapis.com/css?family=Space+Mono");

.number__wrapper
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 400px;
  height: 200px;
  position: absolute;

.number
  font-family: "Space Mono";
  font-size: 1em;
  transform: translateY(70px);
  animation: rising-numbers 0.7s linear infinite;

  &::before
    content: "1";

  &:nth-child(even)
    &::before
      content: "0";

  &:nth-child(2)
    animation-delay: 0.3s;

  &:nth-child(3)
    animation-delay: 0.1s;

  &:nth-child(4)
    animation-delay: 0.6s;

  &:nth-child(5)
    animation-delay: 0.4s;

  &:nth-child(6)
    animation-delay: 0.5s;

  &:nth-child(7)
    animation-delay: 0.2s;

  &:nth-child(8)
    animation-delay: 0.7s;

  &:nth-child(9)
    animation-delay: 0.3s;
  &:nth-child(10)
    animation-delay: 0.6s;

@keyframes rising-numbers
  from
    transform: translateY(70px);
    opacity: 0;
  to
    transform: translateY(-70px);
    opacity: 1;
