.dialog--header {
  font-size: 16px !important;
  padding-top: 4px !important;
  padding-bottom: 4px !important;
}
.dialog--subHeader {
  font-size: 12px;
  font-weight: normal;
}
.dialog--text {
  font-size: 12px;
  text-align: left;
}
.dialog--wrapper {
  overflow-y: auto;
  overflow-x: hidden;
}
>>> b {
  color: var(--v-ternary-base);
}
/*# sourceMappingURL=src/components/common/dialogs/BaseDialog.css.map */