







































































.top-padding
  margin-top: -100px;
.logoTopSpace
  margin-top: 100px;

.buttonSpace
  margin-left: 10px;
