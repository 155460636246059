
































































































































html { overflow-y: auto }

/* Common across all layouts */
.no-uppercase
  text-transform: unset !important;

.v-navigation-drawer
  overflow: visible !important

.v-navigation-drawer__border
  cursor: ew-resize;
  width: 5px
  right: -5px

.main_content
  box-sizing: border-box;
  width: 100%;
  height: 100vh;

.current-design .theme--dark.v-navigation-drawer
  background: var(--v-secondary-darken4) !important

.mimir-redesign .theme--dark.v-navigation-drawer
  background: var(--v-deepBlue-base) !important

.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border
  background-color: hsla(0, 0%, 100%, 0)

.theme--dark.v-navigation-drawer:not(.v-navigation-drawer--floating) .v-navigation-drawer__border
  background-color: rgba(255, 255, 255, 0)

.features-rich #nav-drawer
  overflow: hidden
  top: 52px !important
  .v-list-item__action
    margin: 6px 12px 6px 0
    i
      font-size: 22px

  .v-list-item__title
    padding-top: 1px

  .v-list-item--active
    &:before
      opacity: 0

.features-export .v-list-item__action
  margin-right: 8px !important

// global custom scrollbar
*::-webkit-scrollbar
  width: 16px;
  background-color: var(--v-scrollBarBgColor-base);

*::-webkit-scrollbar-track
  border: .5px solid var(--v-scrollBarTrackBgColor-base);
  border-radius: 8px;

*::-webkit-scrollbar-thumb
  height: 56px;
  border-radius: 8px;
  border: 4px solid transparent;
  background-clip: content-box;
  background-color: var(--v-scrollbarThumbColor-base);

*::-webkit-scrollbar-thumb:hover
  background-color: var(--v-scrollbarThumbHoverColor-base);

// global v-menu color theme for dark theme
.theme--dark.v-application
  background-color: var(--v-vMenuBgColor-base);

.theme--dark.v-list
  background: var(--v-vMenuBgColor-base);

.theme--dark.v-list-item:hover:before
    opacity: 0.14;

// global v-menu color theme for light theme
.theme--light.v-application
  background-color: var(--v-vMenuBgColor-base);

.theme--light.v-list
  background: var(--v-vMenuBgColor-base);

.theme--light.v-list-item:hover:before
    opacity: 0.14;

// global table background color
.custom-table-color
  th
    background: var(--v-tableHeaderBgColor-base) ;
  tr
    background: var(--v-tableRowBgColor-base);

// global date time picker custom color
.theme--dark.v-picker__body
  background: var(--v-vMenuBgColor-base);

.theme--dark.v-card
  background-color: var(--v-vMenuBgColor-base);

.v-menu__content
  background-color: var(--v-vMenuBgColor-base);

.theme--dark.v-time-picker-clock
  background: var(--v-bgColor-base);

.v-time-picker-title
  color: var(--v-timePickerTitleColor-base);

// global v-skeleton-loader__article custom color
.theme--dark.v-skeleton-loader .v-skeleton-loader__article
  background: transparent !important;

.theme--light.v-skeleton-loader .v-skeleton-loader__article
  background: transparent !important;

// appbar button active UI
.v-btn-toggle > .v-btn.v-btn--active
    border-bottom: 4px solid #3A85C9;
