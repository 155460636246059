










































.text
  font-size: 12 px;
  border-radius: 10px;

.alternate-background
  background: #EEEEEE;

.normal-background
  background: #E0E0E0;

.progressBorder
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  font-size: 1rem;
