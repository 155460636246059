




























.button-group
  margin: auto;

.button-class
  float: right;
  margin-left: .5rem;
