






























































































.dialog
  &--header
    font-size: 16px !important
    padding-top: 4px !important
    padding-bottom: 4px !important
  &--subHeader
    font-size: 12px
    font-weight: normal
  &--text
    font-size: 12px
    text-align: left
  &--wrapper
    overflow-y: auto
    overflow-x: hidden
>>> b
  color: var(--v-ternary-base)
